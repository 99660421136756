@import 'shared/assets/stylesheets/core.scss';

.kitchen-sink {
    padding-top: rem(200);

    &__h1 {
        font-family: $heading-font-family;
        font-size: rem(48);
        font-weight: 700;
        line-height: 120%;
        color: $color-brand;
        margin: rem(50) 0 rem(10) 0;
    }

    &__hr {
        border: 1px solid $color-brand;
        margin-bottom: rem(40);
    }

    &__h2 {
        font-family: $heading-font-family;
        font-size: rem(18);
        font-weight: 700;
        line-height: 120%;
        color: $color-teal;
        margin-bottom: rem(20);
        // background: #e7e7e7;
        padding: rem(10) rem(20);
        width: fit-content;
        margin-top: rem(40);
        padding: rem(10);
        border-bottom: rem(.5) dotted $color-brand;
        width: 100%;
    }

    &__tabbedNav {
        margin-bottom: rem(200) !important;
    }
}

@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.news-card {
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: rem(30);

	&__custom-dots {
		display: flex;
		gap: rem(5);
		justify-content: center;
	}

	&__custom-dot,
	.carousel__dot {
		width: rem(11);
		height: rem(11);
		border-radius: 50%;
		background-color: $color-star-jasmine;
		border: none;
		cursor: pointer;
		padding: 0;

		&--selected {
			background-color: $color-brand;
		}
	}

	&__desktop {
		display: none;
	}

	&__mobile {
		display: flex;
		max-width: rem(260);
		max-height: fit-content;
		padding: rem(30);
		flex-direction: column;
		align-items: flex-start;
		gap: rem(10);
		border-radius: rem(8);
		background: $color-white;
		box-shadow: rem(0) rem(4) rem(20) rem(0) rgba(0, 0, 0, 0.15);

		&__carousel {
			max-width: 100%;
			overflow: hidden;
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: rem(10);
			align-items: center;
			max-height: 100%;
			overflow: hidden;
		}

		&__top-section {
			display: flex;
			gap: rem(10);
			align-items: baseline;
			flex-direction: column;

			&__headers {
				display: flex;
				flex-direction: row;
				gap: rem(10);
				align-items: baseline;
			}
		}

		&__bottom-section {
			display: flex;
			flex-direction: column;
		}

		&__imgWrapper {
			height: rem(203);
		}

		img {
			object-fit: cover;
			max-width: rem(255);
			flex-shrink: 0;
			border-radius: rem(8);
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat;
		}

		h3 {
			color: $color-brand;
			font-family: $text-font-family;
			font-size: rem(24);
			font-style: normal;
			font-weight: 600;
			line-height: rem(34);
			text-transform: capitalize;
		}

		h5 {
			color: $color-brand;
			font-family: $text-font-family;
			font-size: rem(14);
			font-style: normal;
			font-weight: 600;
			line-height: rem(24);
			letter-spacing: rem(2.1);
			text-transform: uppercase;
		}

		p {
			max-width: 100%;
			color: $color-black;
			font-family: $text-font-family;
			font-size: rem(14);
			font-style: normal;
			font-weight: 400;
			line-height: rem(25);
		}
	}

	@include after-phone {
		&__mobile {
			max-width: rem(480);
		}
	}

	@include after-tablet {

		&__mobile {
			display: none;
		}

		&__desktop {
			display: flex;
			align-items: center;
			gap: rem(50);
			justify-content: center;
			max-width: 100%;
			height: fit-content;

			&__box-container {
				border-radius: rem(8);
				background: $color-white;
				box-shadow: rem(0) rem(4) rem(30) rem(0) rgba(0, 0, 0, 0.15);
				padding: rem(36) rem(30);
				max-width: rem(700);
			}

			&__container {
				display: flex;
				gap: rem(35);
			}

			&__content {
				display: flex;
				flex-direction: column;
				gap: rem(10);
			}

			&__headers {
				display: flex;
				align-items: baseline;
				gap: rem(20);
			}

			&__imgWrapper {
				width: rem(235);
				height: rem(253);
			}

			img {
				object-fit: cover;
				border-radius: rem(8);
				width: 100%;
				height: 100%;
			}

			h3 {
				color: $color-brand;
				font-family: $text-font-family;
				font-size: rem(16);
				font-style: normal;
				font-weight: 600;
				line-height: rem(28);
				letter-spacing: rem(2.4);
				text-transform: uppercase;
			}

			h5 {
				color: $color-brand;
				font-family: $text-font-family;
				font-size: rem(28);
				font-style: normal;
				font-weight: 600;
				line-height: rem(38);
				text-transform: capitalize;
			}

			p {
				max-width: rem(488);
				color: $color-black;
				font-family: $text-font-family;
				font-size: rem(14);
				font-style: normal;
				font-weight: 400;
				line-height: rem(25);
			}
		}
	}
}
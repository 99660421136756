@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';


.resourceCard {
  display: flex;
  flex-direction: column;
  margin-top: rem(70);
  margin-bottom: rem(70);

  &__date {
    font-family: $text-font-family;
    font-size: rem(16);
    font-style: normal;
    font-weight: 500;
    line-height: rem(28);
    /* 175% */
    letter-spacing: rem(2.4);
    text-transform: uppercase;
    color: $color-brand;
    margin-bottom: rem(20);
  }

  &__title {
    font-family: $heading-font-family;
    font-size: rem(40);
    font-style: normal;
    font-weight: 400;
    line-height: rem(50);
    /* 125% */
    letter-spacing: rem(0.8);
    text-transform: capitalize;
    color: $color-brand;
    margin-bottom: rem(15);
  }

  &__subTitle {
    font-family: $heading-font-family;
    font-size: rem(20);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: rem(0.4);
    color: $color-brand;
    margin-bottom: rem(30);
  }

  &__description {
    font-family: $text-font-family;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }

  &__buttons {
    display: flex;
    gap: rem(30);
    margin-top: rem(30);
    flex-wrap: wrap;

    &Btn {
      padding: rem(8) rem(15);
      color: #002957;
      font-family: Open Sans;
      font-size: rem(15);
      font-weight: 600;
      letter-spacing: rem(2.4);
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;

      svg {
        font-size: rem(17);
      }
    }
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import "shared/assets/stylesheets/vars.scss";

.scholarships {
  padding: 0 7.5rem;
  max-width: rem(1550);
  margin: 0 auto;

  @include tablet {
    padding: 0;
  }

  &__card {
    display: grid;
    grid-template-columns: 10rem 1fr rem(290);
    border: solid rem(1) #ccc;
    border-radius: 0.5rem;
    min-height: 10rem;
    margin-bottom: 1rem;
    font-family: $text-font-family;
    box-shadow: #ccc 0rem rem(3) rem(10);
    background: $color-white;

    @include desktop {
      display: block;
    }

    &:nth-of-type(3n+1) {
      .branding {
        background: $color-brand url('~shared/assets/images/logo-transparent.png') no-repeat center center;
        background-size: 90%;
      }
    }

    &:nth-of-type(3n+2) {
      .branding {
        background: $color-periwinkle url('~shared/assets/images/logo-transparent.png') no-repeat center center;
        background-size: 90%;
      }
    }

    &:nth-of-type(3n+3) {
      .branding {
        background: $color-blue url('~shared/assets/images/logo-transparent.png') no-repeat center center;
        background-size: 90%;
      }
    }

    .branding {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      @include desktop {
        display: none;
      }
    }

    .info {
      padding: 2rem;

      &__name {
        font-size: rem(28);
        font-weight: 600;
        line-height: 2rem;

        @include tablet {
          text-align: center;
        }

        a {
          color: $color-brand;

          &:hover {
            animation: btn-extend-underline .5s forwards;
          }
        }
      }

      &__details {
        display: grid;
        grid-template-rows: repeat(4, auto);
        grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
        margin-top: 2rem;
        font-size: rem(14);

        @include desktop-large {
          grid-template-columns: 1fr;
        }

        .list-item {
          margin-bottom: 0.5rem;
          flex-basis: 50%;
          flex-grow: 1;
          line-height: normal;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          @include tablet {
            grid-template-columns: 8rem minmax(auto, 9rem);
            display: grid;
            justify-content: center;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &__key {
            font-weight: 600;
            margin-right: 1rem;
          }

          &__value {
            overflow-wrap: break-word;
            padding-right: 0.5rem;

            @include phone {
              padding-right: 0;
            }
          }
        }
      }
    }

    .actions {
      background: $color-light-periwinkle;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      font-size: rem(14);
      text-align: center;
      align-content: center;

      * {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__amount {
        font-size: rem(28);
      }

      &__more-details {
        a {
          color: $color-brand;

          svg {
            margin-right: 0.5rem;
          }

          display: inline-block;
          border: solid rem(1) $color-brand;
          border-radius: 2rem;
          padding: 0.5rem;
          letter-spacing: rem(3);
          font-size: rem(14);

          &:hover {
            animation: btn-transparent .5s forwards;
          }
        }
      }

      &__cta {
        a {
          color: $color-white;

          &:hover {
            animation: btn-blue .5s forwards;
          }
        }
      }
    }
  }

  [role=navigation] {
    display: flex;
    font-family: $heading-font-family;
    justify-content: center;


    li {
      margin-right: 1rem;
      font-weight: 600;
      cursor: pointer;

      &.selected {
        border-bottom: solid rem(1) $color-brand;
        padding-bottom: rem(4);
      }

      a {
        color: $color-brand;
      }
    }
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.tri-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: rem(45);
  width: 100%;

  .card {
    box-shadow: 0 rem(4) rem(30) 0 rgba(0, 0, 0, 0.15);
    border-radius: rem(8);

    &__innerContent {
      align-self: center;
    }

    &__arrow {
      background-color: $color-brand;
      height: 100%;
      width: rem(75);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: rem(8);

      svg {
        font-size: rem(31);

        path {
          fill: $color-white;
        }
      }
    }

    &__button-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(12);
      flex-wrap: wrap;
      gap: rem(20) rem(10);
    }

    &__date {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: rem(14);
      font-weight: 400;
      letter-spacing: rem(2.1);
      text-transform: uppercase;
    }

    &__heading {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: rem(20);
      font-weight: 600;
      text-transform: capitalize;
      line-height: 135.714%;
      margin-bottom: rem(11);
      max-width: rem(380);
    }

    &__body {
      color: #000;
      font-family: $text-font-family;
      font-size: rem(14);
      font-weight: 400;
      overflow: hidden;
      line-height: 178.571%;
      display: -webkit-box; // using flexbox layout mode
      -webkit-box-orient: vertical;
    }

    &__keep-reading {
      font-family: $text-font-family;
      color: $color-brand;
      font-size: rem(14);
      font-weight: 600;
      line-height: rem(25);
    }

    &__image {
      height: rem(300);
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: rem(8);
        border-top-right-radius: rem(8);
      }
    }

    &:nth-child(3) {
      order: -1;
      display: flex;
      flex-direction: column;

      .card__inner {
        display: flex;
        justify-content: space-between
      }
    }

    &__inner {
      height: 100%;
      cursor: pointer;

      &:hover {
        h4 {
          text-decoration: underline;
        }

        .card__arrow {
          svg {
            margin-left: rem(5);
          }
        }
      }

      &Content {
        padding: rem(20);
      }
    }
  }

  .button-hovered .card__inner {
    h4 {
      text-decoration: none !important;
    }

    .card__arrow {
      svg {
        margin-left: 0 !important;
      }
    }
  }

  @include after-phone {
    .card {
      &__heading {
        font-size: rem(28);
      }

      &__arrow {
        width: rem(90);
      }
    }
  }

  @include tablet {
    grid-gap: rem(40);
  }

  @include after-tablet {
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(45) rem(65);

    .card {
      &__innerContent {
        padding: rem(30);
        align-self: center;
      }

      &:nth-child(3) {
        order: 0;
      }

      &:first-child {
        grid-row: 1;
        grid-column: 1;
      }

      &:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
      }

      &:nth-child(3) {
        grid-row: 1 / span 2;
        grid-column: 2;
      }
    }
  }

}
@import 'shared/assets/stylesheets/core.scss';
@import 'shared/assets/stylesheets/mixins.scss';

// Common styles for both variations

.tabbed-carousel {
  max-width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;

  &__container {
    align-self: baseline;
    margin-bottom: rem(24);
    flex-direction: column;
  }

  &__heading {
    font-family: $text-font-family;
    font-size: rem(28);
    margin-bottom: rem(25);
    margin-top: rem(30);
    text-transform: capitalize;
    overflow-wrap: break-word;
  }

  h4 {
    font-family: $heading-font-family;
    font-size: rem(18);
    color: $color-brand;
    font-weight: 700;
    margin-bottom: rem(18);
    overflow-wrap: break-word;
  }

  &__image {
    width: 100%;
    flex-shrink: 0;
    height: rem(343);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: rem(8);
      border-top-right-radius: rem(8);
      border-bottom-left-radius: 0
    }
  }

  &__description {
    font-family: $text-font-family;
    font-size: rem(16);
    font-weight: 400;
    line-height: 175%;
    overflow-wrap: break-word;
  }

  &__tabs {
    display: none;
    gap: rem(50);
    margin-bottom: rem(45);
  }

  &__content {
    position: relative;

    h4,
    p {
      color: $color-black !important;
    }

    &-container {
      padding: rem(30);
    }
  }

  &__slider-container {
    box-shadow: 0 rem(14) rem(40) rgba(0, 0, 0, 0.15);
  }

  &__story-box {
    display: none;
    position: relative;
    background-color: $color-white;
    border-radius: rem(8);
    position: relative;
    z-index: 1;
  }

  &__slider-container {
    display: flex;
    flex-direction: row;
    gap: rem(20);
    border-radius: rem(12);
    background: $color-white;
  }

  .carousel {
    position: relative;
    z-index: 1;

    &__slider-tray-wrapper {
      overflow: hidden;
    }
  }

  &__dots-container {
    display: none;
    justify-content: center;
    margin-top: rem(41); // Increased margin-top

    .carousel__dot--selected {
      background: #D9D9D9;
    }

    @include phone {
      display: flex;
    }
  }

  &__dot {
    width: rem(16);
    height: rem(16);
    background: $color-white;
    border: rem(1.5) solid #B5B5B5;
    border-radius: 50%;
    margin: 0 rem(8);
    cursor: pointer;
  }

  &--left-aligned {
    .tabbed-carousel__story-box {
      flex-direction: column;
    }
  }

  &--brackets {
    .tabbed-carousel__container {
      position: relative;
    }

    .tabbed-carousel__heading {
      text-align: center;
      margin-bottom: rem(70);
      font-size: rem(36);
      font-family: $heading-font-family;
    }

    .tabbed-carousel__tabs {
      justify-content: center;
    }

    .tabbed-carousel__slider-container {
      position: relative;
      border-radius: rem(12);
    }

    .tabbed-carousel__content {

      &::before,
      &::after {
        content: '';
        position: absolute;
        background: $color-brand;
        width: rem(184);
        height: rem(166);
        border-radius: rem(8);
      }

      &::before {
        top: rem(-20);
        left: rem(-20);
      }

      &::after {
        bottom: rem(-20);
        right: rem(-20);

        @include phone {
          //acounts for dots showing up on mobile
          bottom: rem(35);
        }
      }
    }
  }

  &__innerSlider {
    display: block;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  @include after-tablet {
    &__content {
      &-container {
        padding: rem(65);
      }
    }

    &__image {
      height: auto;
      width: rem(390);

      img {
        border-top-left-radius: rem(8);
        border-bottom-left-radius: rem(8);
        border-top-right-radius: 0;
      }
    }

    &--left-aligned {
      .tabbed-carousel__story-box {
        flex-direction: row;
      }
    }

    &--brackets {
      .tabbed-carousel__heading {
        margin-bottom: rem(30);
        font-size: rem(40);
      }

      .tabbed-carousel__content {
        &::before {
          top: rem(-50);
          left: rem(-50);
        }

        &::after {
          bottom: rem(-50);
          right: rem(-50);
        }
      }
    }

    &__innerSlider {
      display: flex;
      position: relative;
      background-color: $color-white;
      border-radius: rem(8);
      z-index: 1;
    }
  }

  @include after-phone {
    &__tabs {
      display: flex;
    }

    &--brackets {
      .tabbed-carousel__heading {
        margin-bottom: rem(30);
        font-size: rem(40);
      }
    }
  }
}
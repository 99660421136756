@import 'shared/assets/stylesheets/core.scss';

.icon-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  &__container {
    max-width: rem(1440);
  }

  &__heading,
  &__sub-heading,
  &__text {
    text-align: center;
  }

  &__heading {
    font-family: $heading-font-family;
    font-size: rem(40);
    font-weight: 500;
    letter-spacing: rem(0.8);
    text-transform: capitalize;
    margin-bottom: rem(38);
  }

  &__highlight {
    font-weight: bold;
  }

  &__sub-heading {
    font-family: $heading-font-family;
    font-size: rem(20);
    font-weight: 700;
    letter-spacing: rem(0.4);
    margin-bottom: rem(18);
  }

  &__text,
  &__anchor {
    font-family: $text-font-family;
    font-size: rem(16);
    font-weight: 600;
    line-height: rem(28);
    letter-spacing: rem(2.4);
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__anchor:hover {
    text-decoration: underline;
  }

  &__icons-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(40);
    justify-content: center;
    justify-items: center;
  }

  &__icon {
    max-width: rem(173);
    width: 100%;
  }

  &--Small {
    .icon-banner__icons-wrapper {
      width: fit-content;
      margin: 0 auto;
    }

    .icon-banner__icon {
      width: rem(37);
      max-width: rem(37);
    }
  }

  &--ContactInfo &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(40);
  }

  &__single-icon {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: rem(286);
    }
  }

  &__inner-grid,
  &__connect,
  &__directions,
  &__contact-numbers,
  &__connect {
    display: flex;
    flex-direction: column;
  }

  &__inner-grid {
    gap: rem(40);
  }

  &__contact-numbers {
    svg {
      margin-right: rem(8);
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &__connect {
    align-items: center;
  }

  @include after-phone {

    &__sub-heading,
    &__text {
      text-align: left;
    }

    &__icons-wrapper {
      gap: rem(68);
    }

    &__container {
      width: 100%;
    }

    &--Large &__icons-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(rem(173), auto));
    }

    &--Small &__icons-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(rem(37), auto));
      width: 100%;
      margin: 0;
    }

    &--ContactInfo &__container {
      flex-direction: row;
      justify-content: center;
      gap: rem(68);
    }

    &__single-icon {
      width: rem(286);

      img {
        width: 100%;
      }
    }

    &__inner-grid {
      flex-direction: row;
      gap: rem(68);
      width: auto;
      flex-wrap: wrap;
    }

    &__connect {
      display: block;
    }
  }
}
@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";
@import 'shared/assets/stylesheets/core.scss';

section {

  &.copy-block__connect-with-expert__container {
    background-color: $color-green;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: $radialImage;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom rem(-14);
    background-size: 26%;

    @include tablet {
      background-size: 50%;
    }

    @include phone {
      background-size: 85%;
    }
  }

  .copy-block {
    &--intro {
      display: inline-grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      grid-gap: 5rem;
      text-align-last: left;

      @include phone {
        grid-template-columns: auto
      }

      h2 {
        color: $color-brand;
        font-family: $heading-font-family;
        font-style: normal;
        font-weight: 500;
        line-height: rem(50);
        letter-spacing: rem(0.8);
        text-transform: capitalize;
        font-size: rem(40);
        margin-bottom: 0;
      }

      p {
        color: $color-black;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);
        font-family: $text-font-family;
        max-width: rem(699.929);
      }

      &>a {
        color: $color-brand;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: rem(20);
        letter-spacing: rem(2.4);
        text-transform: uppercase;
        width: fit-content;

        &:hover {
          border-bottom: solid rem(1) $color-green;
          padding-bottom: rem(2);
        }
      }

      &--column {
        text-align-last: left;

        &__content {
          display: flex;
          flex-direction: column;
          gap: 1.875rem;
        }

        h2 {
          color: $color-brand;
          font-family: $heading-font-family;
          font-style: normal;
          font-weight: 500;
          line-height: rem(50);
          letter-spacing: rem(0.8);
          text-transform: capitalize;
          font-size: rem(40);
          margin-bottom: 0;
        }

        p {
          color: $color-black;
          font-family: $text-font-family;
          font-size: rem(16);
          font-style: normal;
          font-weight: 400;
          line-height: rem(28);
          font-family: $text-font-family;
          max-width: rem(699.929);
        }

        &>a {
          color: $color-brand;
          font-family: $text-font-family;
          font-size: rem(16);
          font-style: normal;
          font-weight: 600;
          line-height: rem(20);
          letter-spacing: rem(2.4);
          text-transform: uppercase;
          width: fit-content;

          &:hover {
            border-bottom: solid rem(1) $color-green;
            padding-bottom: rem(2);
          }
        }
      }
    }

    &__connect-with-expert {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      text-align-last: left;
      max-width: rem(1250);
      margin: rem(90) 2rem;
      padding: 0 13rem;

      h1 {
        font-family: $text-font-family;
        font-size: rem(28);
        margin-bottom: 0;
        text-align: left;
        color: $color-brand;
        font-weight: 600;
        width: 100%;
        text-transform: capitalize;
      }

      &__block {
        gap: 7rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        p {
          font-family: $text-font-family;
          font-size: 1rem;
          line-height: rem(28);
          width: 100%;
          max-width: rem(940);
        }

        @include tablet {
          flex-direction: column;
          gap: 2.5rem;
        }
      }

      @include tablet {
        padding: 0 1rem;
        gap: 2rem;
      }
    }

    &--page-desc {
      display: inline-grid;
      grid-template-columns: auto auto;
      align-items: center;
      grid-gap: 1rem;
      padding: 5rem 0;
      text-align-last: left;
      max-width: rem(1400);

      h1 {
        font-family: $heading-font-family;
        font-size: rem(28);
        margin-bottom: 0;
        grid-column: 1;
      }

      p {
        font-family: $text-font-family;
        font-size: rem(14);
        line-height: 1.5rem;
        grid-column: 1;
      }

      &>a {
        color: $color-brand;
        font-family: $text-font-family;
        grid-column: 2;

        &:hover {
          border-bottom: solid rem(1) $color-green;
          padding-bottom: rem(2);
        }
      }
    }

    &--intro-icon {
      display: inline-grid;
      grid-template-columns: auto auto;
      align-items: center;
      grid-gap: 1rem;
      padding: 1rem 0;
      text-align-last: left;
      max-width: rem(1024);

      svg {
        color: #fff;
        grid-column: 1;
        grid-row: 1/3;
        border-radius: 3rem;
        background-color: $color-dusty-blue;
        padding: 2rem;
        width: 2rem;
        height: 2rem;
      }

      h1 {
        font-family: $text-font-family;
        font-size: rem(28);
        margin-bottom: 0;
        grid-column: 2;
      }

      p {
        font-family: $text-font-family;
        font-size: rem(14);
        line-height: 1.5rem;
        grid-column: 2;
      }

      &>a {
        color: $color-brand;
        font-family: $text-font-family;
        grid-column: 2;

        &:hover {
          border-bottom: solid rem(1) $color-green;
          padding-bottom: rem(2);
        }
      }
    }

    &__single-column {
      display: inline-grid;
      grid-template-rows: auto auto auto;
      align-items: center;
      justify-items: left;
      grid-gap: 2rem;
      padding: 1rem 0;
      text-align-last: left;
      margin: 5rem 2rem;
      max-width: rem(940);

      h1 {
        font-family: $heading-font-family;
        font-size: rem(40);
        margin-bottom: 0;
        grid-column: 1;
        color: $color-brand;
        line-height: rem(50);
        letter-spacing: rem(0.8);
        text-align: center;
        text-transform: capitalize;
        font-weight: 500;

        @include tablet {
          font-weight: 800;
          letter-spacing: rem(1.05);
          line-height: normal;
          font-family: $inter-font-family;
        }
      }

      p {
        font-family: $text-font-family;
        font-size: 1rem;
        line-height: rem(28);
        grid-column: 1;
        font-weight: rem(400);
      }

      @include tablet {
        margin: 2rem 2rem;
        font-family: $inter-font-family;
      }

      @include phone {
        margin: 1rem 2rem;
        grid-gap: 1rem;
      }
    }

    &__text-intro-two-column {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100%;

      @include phone {
        justify-content: flex-start;
      }

      &__grid {
        display: grid;
        grid-template-columns: minmax(0, rem(460)) minmax(0, rem(760));
        gap: rem(75);
        align-items: center;
        max-width: 100%;
        padding: rem(0) rem(20);

        h1 {
          font-family: $heading-font-family;
          font-size: rem(40);
          margin-bottom: 0;
          color: $color-brand;
          line-height: rem(50);
          font-weight: 500;
          text-transform: capitalize;
          letter-spacing: rem(0.8);
          text-align-last: auto;

          @include tablet {
            font-size: rem(36);
            line-height: rem(46);
            letter-spacing: rem(0.76);
            text-align: left;
          }
        }

        p {
          font-family: $text-font-family;
          font-size: 1rem;
          line-height: rem(28);
          text-align-last: left;
        }

        @include tablet {
          grid-template-columns: 1fr;
          grid-gap: rem(20);
        }

        @include phone {
          grid-gap: rem(10);
        }

        &__top {
          @include tablet {
            display: flex;
            justify-content: flex-start;
          }
        }
      }

      @include phone {
        padding-top: 1.5rem;
      }
    }

    &--text-two-column-list {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      padding-bottom: 2.875rem;
      align-items: center;
      justify-content: center;

      @include phone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2.188rem 0rem 0rem 0.188rem;
      }

      &__board,
      &__board--border {
        gap: 4.375rem;
        padding-bottom: 1.5rem;
        max-width: max-content;

        @include phone {
          width: 100%;
          gap: 0.625rem;
        }

      }

      &__board--border {
        border-bottom: 0.063rem solid $color-solid-gray;
      }

      &__flex-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.813rem;
      }

      &__header {
        display: flex;
        align-items: baseline;
        gap: rem(10);

        @include phone {
          flex-direction: column;
        }

        @include tablet {
          flex-direction: column;
        }

        h1 {
          color: $color-brand;
          font-family: $heading-font-family;
          font-size: rem(40);
          font-style: normal;
          font-weight: 500;
          line-height: rem(50);
          letter-spacing: rem(0.8);
          text-align: left;
        }
      }

      .copy-block__single-column {
        margin: 2rem 0rem;
      }

      &__content {
        display: flex;
        gap: 11.563rem;
        align-items: center;

        @include phone {
          flex-direction: column;
          width: fit-content;
          gap: 1.563rem;
          align-items: flex-start;
        }

        @include tablet {
          flex-direction: column;
          width: fit-content;
          gap: 1.563rem;
          align-items: flex-start;
        }
      }

      h2 {
        color: $color-brand;
        font-family: $heading-font-family;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.125rem;
        letter-spacing: 0.05rem;
        text-transform: capitalize;
        text-align: left;
        text-align-last: left;
      }

      h4 {
        max-width: 49.313rem;
        color: $color-brand;
        font-family: $heading-font-family;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.875rem;
        letter-spacing: 0.025rem;
        text-align-last: left;

        @include phone {
          margin-bottom: rem(12);
        }
      }

      h5,
      span {
        color: $color-brand;
        font-family: $text-font-family;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.75rem;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
      }

      p {
        max-width: 51.5rem;
        color: $color-black;
        font-family: $text-font-family;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
        text-align-last: left;

        @include phone {
          width: fit-content;
        }
      }

      span {
        @include phone {
          display: none;
        }

        @include tablet {
          display: none;
        }
      }
    }

    &--text-two-column-list-header {
      margin-bottom: 2.875rem;

      @include phone {
        margin-bottom: 1.875rem;
      }
    }
  }

  .resource-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    width: 100%;

    .copy-block--intro--column h2 {
      padding: 0;
    }

    .copy-block--intro--column p {
      max-width: rem(1181.008);
    }

    &__resource-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__resource-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: inherit;

      &.resource-list__resource--alt {
        background-color: $color-smoke;
      }
    }

    &__resource {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(30);
      padding: rem(20) rem(0);
      width: 100%;
      max-width: rem(1200);
      text-align: left;
      margin: 0 20%;

      &__title {
        flex: 1;

        h4 {
          color: $color-brand;
          font-family: $heading-font-family;
          font-size: rem(20);
          font-style: normal;
          font-weight: 700;
          line-height: rem(30);
          letter-spacing: 0.025rem;
          max-width: rem(200);
          margin: rem(0);
        }
      }

      &__paragraph {
        flex: 2;

        .rte {
          p {
            max-width: rem(508);
            text-align: left;
            font-family: $text-font-family;
            font-size: rem(16);
            font-style: normal;
            font-weight: 400;
            line-height: rem(28);
            margin: 0;
          }
        }
      }

      &__button {
        flex: 1;
        width: fit-content;
        margin: rem(0);
      }

      @include phone {
        flex-direction: column;
        align-items: baseline;
        text-align: left;
        margin: rem(0) rem(20);
      }
    }
  }

  .column-lists {
    max-width: rem(1440);
    margin: 0 auto;
    padding: 2.5rem 5rem;

    &__container {
      margin: 1.25rem 0.938rem;
    }

    h2 {
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 3.125rem;
      letter-spacing: 0.005rem;
      text-transform: capitalize;
      text-align: left;
      text-align-last: left;
    }
  }

  .column-list {
    p {
      color: $color-black;
      font-family: $text-font-family;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: rem(28);
      font-family: $text-font-family;
      text-align: left;
      text-align-last: left;
    }

    h3 {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.375rem;
      text-transform: capitalize;
      text-align: left;
      text-align-last: left;
    }

    .column-list__layout {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.75rem;
      letter-spacing: normal;
      text-transform: none;
      text-align: left;
      text-align-last: left;
      padding: 1.25rem 1.875rem;

      ul,
      ol {
        // Instead of using display: grid, use column-count for a column layout
        column-count: 3;
        column-gap: 5%;
        margin: 0;
        padding: 0;

        li {
          border-right: 0.063rem solid $color-ephemeral-blue;
          width: 100%;
          margin-bottom: 0;
          padding: rem(0);
        }
      }

      ol {
        column-gap: 6%;
      }

      @include tablet {

        ul,
        ol {
          column-count: 2;

          li {
            border-right: 0.063rem solid $color-ephemeral-blue;
            width: 90%;
          }
        }

        ol {
          column-gap: 10%;
        }
      }

      @include phone {

        ul,
        ol {
          column-count: 1;

          li {
            border-right: none;
          }
        }
      }
    }
  }
}

.two-column-list {
  display: flex;
  flex-direction: column;
  gap: rem(10);
  height: fit-content;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }

  @include after-phone {

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(16);
    }
  }
}

.three-column-list {

  section {
    &:not(:last-child) {
      .copy-block {
        border-bottom: rem(1) solid $color-solid-gray;
      }
    }
  }

  .copy-block {
    &--intro {
      grid-template-columns: auto;
      padding: rem(40) rem(0) rem(41) rem(10);
      grid-gap: 2rem;
    }
  }

  @include after-phone {
    .copy-block {
      &--intro {
        display: inline-grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        grid-gap: 5rem;
        padding: 5rem 0;
        text-align-last: left;
      }
    }
  }
}

.four-row-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(38);

  &__content {
    display: flex;
    flex-direction: column;
    max-width: rem(326);
    padding: rem(31) rem(0);
    gap: rem(27);
    border-radius: rem(8);
    background: $color-white;
    box-shadow: rem(0) rem(4) rem(20) rem(0) rgba(0, 0, 0, 0.15);

    &__fa-section {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    svg {
      text-align: center;
      font-size: rem(50);
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: rem(7.5);
      text-transform: uppercase;
    }

    &__icon-background {
      display: flex;
      width: rem(124);
      height: rem(124);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: rem(120);

    }

    &__description-section {

      &__content {
        display: flex;
        height: auto;
        padding: rem(30);
        flex-direction: column;
        align-items: flex-start;
        gap: rem(16);
      }
    }

    &__divider {
      margin-top: 0;
      margin-bottom: 0;
      height: rem(12);
      width: 100%;
      border: none;
    }

    &__benefits-section {
      display: flex;
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(16);
      padding: rem(0) rem(26) rem(0) rem(40);
    }

    h3 {
      max-width: rem(316);
      color: $color-brand;
      text-align: center;
      font-family: $text-font-family;
      font-size: rem(28);
      font-style: normal;
      font-weight: 600;
      line-height: rem(38);
      text-transform: capitalize;
    }

    h4 {
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: rem(20);
      font-style: normal;
      font-weight: 700;
      line-height: rem(25);
      letter-spacing: rem(0.4);
    }

    .rte {
      p {
        color: $color-black;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);
      }

      ul {
        list-style-type: disc;
        padding: rem(0);
      }

      li {
        color: $color-black;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);
        width: fit-content;
        margin-left: rem(20);
      }
    }
  }

  @include after-phone {
    gap: rem(40);
    padding: rem(20);

    &__content {
      max-width: fit-content;
      flex-direction: row;
      padding: rem(0);
      gap: rem(0);

      &__fa-section {
        padding: rem(10);
        gap: rem(20);
        width: 20%;
        justify-content: center;
      }

      &__description-section {
        display: flex;
        height: auto;
        width: 56%;
      }

      &__divider {
        margin-top: 0;
        margin-bottom: 0;
        width: rem(25);
        height: auto;
        border: none;
        min-width: rem(12);
      }

      &__benefits-section {
        padding: 1.875rem 1.985rem;
        gap: rem(5);
        width: 30%;
      }
    }
  }
}

.h1-intro-one-column {
  display: flex;
  flex-direction: column;
  gap: rem(40);
  max-width: 100%;
  justify-content: center;

  &__content {
    max-width: rem(700);
    width: 100%;

    h1 {
      max-width: rem(338);
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: rem(36);
      font-style: normal;
      font-weight: 500;
      line-height: rem(46);
      letter-spacing: rem(0.72);
      text-transform: capitalize;
    }

    .rte>p {
      max-width: rem(350);
      color: $color-black;
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(28);
    }

    .rte>ul,
    .rte>ol {
      li {
        max-width: rem(350);
      }
    }
  }

  &__button {
    display: flex;
    align-self: flex-end;
  }

  @include after-phone {
    flex-direction: row;
    align-items: center;
    gap: rem(120);

    &__content {
      h1 {
        max-width: rem(787);
      }

      .rte>p,
      .rte>figure {
        max-width: rem(787);

        img {
          width: 100%;
        }
      }

      .rte>ul,
      .rte>ol {
        li {
          max-width: rem(787);
        }
      }
    }
  }
}

.intro-three-column {
  display: flex;
  flex-direction: column;
  gap: rem(30);
  max-width: 100%;
  justify-content: center;
  align-items: baseline;
  height: auto;
  flex-wrap: wrap;

  h1 {
    max-width: rem(350.136);
    color: $color-brand;
    font-family: $heading-font-family;
    font-size: rem(70);
    font-style: normal;
    font-weight: 500;
    line-height: rem(90);
    text-transform: capitalize;
  }

  h2 {
    max-width: rem(350.136);
    color: $color-brand;
    font-family: $heading-font-family;
    font-size: rem(36);
    font-style: normal;
    font-weight: 500;
    line-height: rem(46);
    letter-spacing: rem(0.72);
    text-transform: capitalize;
  }

  .rte>p {
    max-width: rem(349.943);
    transform: rotate(0.07deg);
    color: $color-black;
    font-family: $text-font-family;
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: rem(24);
  }

  @include after-phone {
    flex-direction: row;
    gap: rem(64);
    align-items: center;

    h1 {
      max-width: rem(460);
    }

    h2 {
      max-width: rem(470);
    }

    p {
      max-width: rem(580);
    }
  }
}

.sixty-forty {
  display: flex;
  gap: rem(20);
  border-radius: rem(8);
  border: rem(1) solid #BFBFBF;
  margin: rem(10) rem(0) rem(10) rem(0);

  &__spacer {
    flex: 1;
    border-top-left-radius: rem(8);
    border-bottom-left-radius: rem(8);
  }

  &__content1 {
    flex: 60;
    padding: rem(20);
    padding-top: rem(40);
  }

  &__content2 {
    flex: 39;
    padding: rem(40);
    border-top-right-radius: rem(8);
    border-bottom-right-radius: rem(8);
    background-image: $logo-transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right rem(-250);
    background-size: 90%;
  }

  @include phone {
    flex-direction: column;

    &__spacer {
      min-height: rem(10);
      border-bottom-left-radius: rem(0);
      border-top-right-radius: rem(8);
    }

    &__content2 {
      border-bottom-left-radius: rem(8);
      border-top-right-radius: rem(0);
    }
  }
}

.four-column-table {
  display: flex;
  flex-wrap: wrap;
  gap: rem(20);
  font-family: $text-font-family;
  line-height: rem(28);
  font-size: rem(16);

  &__card {
    width: rem(310);
    font-weight: 400;

    h4 {
      font-family: $heading-font-family;
      font-size: rem(20);
      font-style: normal;
      font-weight: 700;
      line-height: rem(30);
      letter-spacing: rem(.4);
      text-align-last: left;

    }

    h3 {
      font-family: $text-font-family;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.375rem;
      text-transform: capitalize;
      text-align: left;
      text-align-last: left;
      margin-bottom: rem(10);
    }

    &--heading {
      padding: rem(20);
      border-radius: rem(8) rem(8) rem(0) rem(0);
      border-top: rem(1) solid black;
      border-left: rem(1) solid black;
      border-right: rem(1) solid black;
    }

    &--content {
      padding: rem(20);
      border-radius: rem(0) rem(0) rem(8) rem(8);
      border: rem(1) solid black;
    }

    &--content-one {
      margin-bottom: rem(20);

      h1 {
        height: rem(86);
      }

      &--container {
        display: flex;
        justify-content: space-between;

      }
    }

    &--content-two {
      &--container {
        display: flex;
      }

      &--placeholder {
        flex: 1;
      }

      &--content {
        hr {
          margin-top: rem(20);
        }

        flex: 4;

        &-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &--content-three {
      padding-bottom: rem(10);
    }
  }

}
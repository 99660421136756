@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.user-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: rem(1440);
  margin-bottom: rem(86) !important;
  gap: rem(90);

  &__container {

    h2 {
      padding-bottom: rem(30);
      margin: 0;
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: rem(36);
      font-style: normal;
      font-weight: 500;
      line-height: rem(50);
      letter-spacing: rem(0.8);
      text-transform: capitalize;
    }
  }

  // this layout wrapper works modularly with different types of cards
  &__layout {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: rem(13);
    scroll-snap-type: x mandatory;
    padding-bottom: rem(23);

    &>* {
      flex: 0 0 auto;
      scroll-snap-align: start;
    }

    &::-webkit-scrollbar-track {
      margin-right: rem(20);
    }

    &:last-child {
      padding-right: rem(20);
    }
  }

  // Desktop styles
  @include after-phone {
    padding: 0 rem(30) 0 rem(30);

    &__layout {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(rem(230), 1fr));
      gap: rem(21);
      padding: 0;

      &>* {
        max-width: none !important; //this will overwrite the max-width of the cards so grid can take over
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &__container {
      h2 {
        font-size: rem(40);
      }
    }
  }
}

.user-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: rem(16);
  width: 100%;
  max-width: rem(230);
  min-width: rem(150);
  cursor: pointer;

  .user-card__image-wrapper {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      min-height: rem(323);
      object-fit: cover;
      border-radius: rem(8);
    }
  }

  &__info {
    padding-top: rem(10);

    h4 {
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: rem(20);
      font-style: normal;
      font-weight: 700;
      line-height: rem(30);
      letter-spacing: rem(0.4);
      margin-bottom: 0rem;
      text-transform: capitalize;
    }

    p {
      width: auto;
      color: $color-black;
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(28);
    }
  }
}

.profilePopup {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border: none;
  padding: 0;

  &__imageWrapper {
    max-width: rem(310);
    height: rem(400);
    overflow: hidden;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }
  }

  &__content {
    flex-basis: fit-content;

    h3 {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: rem(28);
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: rem(30);
      margin-top: rem(30);
    }

    p,
    strong {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      line-height: 175%;
    }

    strong {
      font-weight: 700;
    }

    &Description {
      margin-top: rem(30);
      padding-bottom: rem(30);
    }
  }

  &__buffer {

    &Top,
    &Bottom {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: rem(20);
      background-color: #fff;
      z-index: 2;
    }

    &Top {
      display: none;
      top: 0;
    }

    &Bottom {
      bottom: 0;
      border-bottom-left-radius: rem(8);
      margin-right: rem(10);
    }
  }

  @include after-tablet {
    position: relative;
    flex-direction: row;
    border: solid rem(1) $color-gray;
    border-radius: rem(8) rem(4) rem(4) rem(8);
    padding-left: rem(40);

    &__imageWrapper {
      height: rem(323);
      max-width: rem(230);
      margin: rem(30) rem(43) rem(30) 0;
    }

    &__content {
      max-height: rem(550);
      overflow-y: auto;
      padding-right: rem(30);

      &Description {
        padding-bottom: rem(30);
      }
    }

    &__buffer {

      &Top,
      &Bottom {
        height: rem(30);
        margin-right: rem(42);
      }

      &Top {
        display: block;
      }
    }
  }
}
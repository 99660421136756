@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";
@import 'shared/assets/stylesheets/core.scss';
@import 'react-big-calendar/lib/sass/styles';

.calendar {
  &--list-views {
    .input--wrapper {
      .input--keyword-search,
      .input--search,
      > label .custom-dropdown {
        margin-left: 10%;
        margin-right: 10%;
        max-width: 80%;
        min-width: rem(196);
      }
    }

    .input--drop-down-container {
      margin-left: 10%;
      margin-right: 10%;
      max-width: 80%;
    }
  }
}

.legend-container {
  display: flex;
  flex-wrap: wrap;
  gap: rem(10);
  margin-left: 10%;
  margin-right: 10%;
  max-width: 90%;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: rem(5);
  margin-top: rem(20);
  width: rem(300);
}

.color-box {
  width: rem(20);
  height: rem(20);
  margin-right: rem(10);
}

.color-label {
  font-size: rem(14);
  color: $color-dark-charcoal;
  font-family: $text-font-family;
}

.rbc-calendar {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
  min-width: rem(324);

  .rbc-time-slot {
    border: none;
    font-family: $text-font-family;
  }

  .rbc-label {
    padding: rem(0) rem(3);
  }

  div.rbc-label.rbc-time-header-gutter {
    background: $color-white;
  }

  .rbc-header {
    padding: rem(12);
    font-family: $text-font-family;
    color: $color-white;
    background-color: $color-brand;
    text-transform: uppercase;
    border: none;
  }

  .rbc-header+.rbc-header {
    border: none;
  }

  .rbc-time-header.rbc-overflowing {
    border: none;
    width: 100%;
  }

  .rbc-time-header-content {
    border-left: rem(1) solid $color-moon-gray;
  }

  .rbc-button-link {
    vertical-align: middle;
    text-transform: uppercase;
    padding-bottom: rem(20);
    font-weight: 700;
  }

  .rbc-month-row+.rbc-month-row {
    border-top: rem(1) solid $color-moon-gray;
  }

  .rbc-day-bg+.rbc-day-bg {
    border-left: rem(1) solid $color-moon-gray;
  }

  .rbc-day-bg {
    background: $color-white
  }

  .rbc-off-range-bg {
    background: $color-gainsboro;
  }

  .rbc-date-cell {
    padding-bottom: rem(10);
  }

  .rbc-day-slot .rbc-background-event,
  .rbc-event {
    background-color: $color-azureish-white;
    border-radius: rem(0);
    padding: rem(10);
    font-family: $text-font-family;
    font-size: rem(14);
    line-height: rem(20);
  }

  .rbc-abs-full,
  .rbc-row-bg {
    margin-bottom: rem(-1);
  }

  .rbc-row-segment {
    padding: rem(0) rem(.35) rem(0) rem(.65);
  }

  .rbc-month-view {
    border: rem(1) solid $color-moon-gray;
  }

  .rbc-toolbar button {
    background: $color-brand;
    border: none;
    border-radius: rem(5) !important;
    color: $color-white;
    padding: rem(14.4) rem(19.2);
    font-family: $text-font-family;
    margin: rem(2);
  }

  .rbc-toolbar .rbc-toolbar-label {
    font-family: $text-font-family;
    font-size: rem(36);
    font-weight: 500;
    line-height: rem(60);
  }

  .rbc-toolbar button.rbc-active,
  .rbc-toolbar button:active {
    color: $color-white;
    background-color: $color-blue-lolite;

    &:hover {
      animation: none;
    }
  }

  .rbc-toolbar button {
    cursor: pointer;

    &:hover {
      animation: btn-calendar .5s forwards;
    }
  }

  .rbc-events-container {
    margin: 0;
  }

  div>.rbc-header,
  .rbc-today {
    background: $color-brand;
  }

  .rbc-today {
    background: $color-alice-blue;
  }

  .rbc-today>.rbc-timeslot-group {
    background: $color-alice-blue;
  }

  .rbc-timeslot-group {
    background: $color-white;
  }

  .rbc-timeslot-group {
    border-bottom: rem(1) solid $color-moon-gray;
  }

  .rbc-time-content {
    border-top: rem(1) solid $color-moon-gray;
  }

  .rbc-time-content>*+*>* {
    border-left: rem(1) solid $color-moon-gray;
  }

  .rbc-time-view {
    border: rem(1) solid $color-moon-gray;
  }

  .rbc-day-slot .rbc-background-event,
  .rbc-event {
    background-color: $color-azureish-white;
    color: $color-white;
    padding: rem(10);
    font-family: $text-font-family;
    font-size: rem(14);
    border: none;
  }

  .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
    padding: rem(10) rem(10);
  }

  .rbc-agenda-time-cell,
  .rbc-agenda-date-cell,
  .rbc-agenda-event-cell {
    color: $color-white;
    font-family: $text-font-family;
  }
}

.event-cards {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(10);
  justify-content: center;
  align-items: center;
  padding: rem(0) rem(10);

  &__card {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: rem(347.742);
    border: rem(2) solid $color-brand;
    height: rem(585);
    width: 100%;

    &__title {
      padding-left: rem(10);
      font-family: $text-font-family;
      font-size: rem(17);
      font-style: normal;
      font-weight: 600;
      line-height: rem(38);
      letter-spacing: rem(3.4);
      text-transform: capitalize;


      h2 {
        overflow-wrap: break-word;
        margin: 0;
      }
    }

    &__top-section {
      padding: rem(0) rem(18);

      h2 {
        max-width: rem(309.289);
        color: $color-brand;
        font-family: $text-font-family;
        font-size: rem(21);
        font-style: normal;
        font-weight: 700;
        line-height: rem(26);
        text-transform: capitalize;
      }

      strong {
        font-weight: 700;
      }

      p {
        color: $color-brand;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        line-height: rem(23);
        text-transform: capitalize;
      }
    }

    &__mid-section {
      padding: rem(18);

      strong {
        font-weight: 700;
      }

      p {
        color: $color-brand;
        max-width: rem(266);
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        line-height: rem(21);
      }
    }

    &__bottom-section {
      padding: rem(0) rem(18);

      strong {
        font-weight: 700;
      }

      p {
        max-width: rem(290);
        color: $color-brand;

        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        line-height: rem(23);
      }
    }

    &__button-section {
      display: flex;
      justify-content: center;
      padding-bottom: rem(20);
    }
  }

  @include after-phone {
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(25);

    &__card {
      max-width: rem(416);
      width: 100%;
    }
  }
}

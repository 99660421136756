@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.input {
  width: 100%;
  color: $color-black;
  background-color: $color-white;
  border: solid rem(1) $color-black;
  border-radius: 2rem;

  svg {
    color: inherit;
  }

  input[type="text"] {
    height: 2rem;
    background: none;
    border: none;
    font-family: $text-font-family;
    font-size: .9rem;
    width: 100%;
    color: $color-black;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $color-black;
      font-family: $text-font-family;
      font-size: .9rem;
      opacity: .5;
    }
  }

  &--wrapper {
    @include tablet {
      width: 100%;
    }

    label {
      color: $color-dark-blue-gray;
      font-family: $text-font-family;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.75rem;
    }
  }

  &--search,
  &--keyword-search {
    display: flex;
    align-items: center;
    border: rem(1) solid var(--sys-medium-contrast, $color-gray);
    height: rem(48);
    margin-top: rem(10);

    @include tablet {
      min-width: unset;
    }

    svg {
      font-size: rem(16);
      margin: 0 rem(12) 0 rem(12);

      path {
        fill: #8A8A8A;
      }
    }
  }

  &--keyword-search {
    border-radius: rem(4);
  }

  &--drop-down-options-custom-icon-box {
    background: $color-brand;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: rem(48);
    border-radius: rem(0) rem(4) rem(4) rem(0);
    height: 100%;

    svg {
      font-size: rem(16);
    }
  }

  &--drop-down-options-custom-icon {
    height: rem(15);
    width: rem(15);
  }

  &--drop-down-options-container {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    margin-top: rem(25);

    .button--clear-all-button {
      padding-left: rem(5);
    }
  }

  &--header-search {
    width: 100%;
    border-radius: 0.05rem;
  }

  &--drop-down-container {
    .custom-dropdown {
      position: relative;
      font-family: $text-font-family;
      font-size: rem(14);
      font-style: normal;
      font-weight: 400;
      line-height: rem(18);
      /* 128.571% */
      text-transform: capitalize;

      &__selected {
        height: rem(48);
        padding: 0 rem(10);
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: rem(10);
        border-radius: rem(4);
        border: rem(1) solid var(--sys-medium-contrast, #BFBFBF);
        position: relative;
        padding-right: rem(58);
      }

      &__selected.isOpen {
        height: rem(44);
        border: rem(3) solid $color-dark-blue-gray;
        outline: rem(6) solid rgba(25, 67, 215, 0.15);

        .input--drop-down-options-custom-icon-box {
          width: rem(48);
          border-radius: rem(0) rem(1) rem(1) rem(0);
        }
      }

      &__options {
        position: absolute;
        width: 100%;
        background-color: white;
        box-shadow: 0 0 rem(16) 0 rgba(0, 0, 0, 0.08);
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: rem(200);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 10;
        border-radius: rem(12);
        padding: rem(8) 0;
        margin-top: rem(5);
      }

      &__option {
        padding: rem(8) rem(16);
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }

      &__placeholder {
        opacity: .5;
        font-family: $text-font-family;
        font-size: .9rem;
      }
    }
  }
}
@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/core.scss';

.image-carousel-50-50__content,
.image-carousel-50-50__content--right {
  display: flex;
  align-items: center;
  gap: rem(50);
  width: 100%;

  @include tablet {
    flex-direction: column;
    max-width: fit-content;
    gap: 0;
    padding: .5rem 1rem 1rem 1rem;
  }

  @include phone {
    padding: .25rem .5rem 1rem .5rem;
  }
}

.image-carousel-50-50__content--right {
  flex-direction: row-reverse;

  @include tablet {
    flex-direction: column;
    max-width: fit-content;
    gap: 0;
    padding: .5rem 1rem 1rem 1rem;
  }

  @include phone {
    padding: .25rem .5rem 1rem .5rem;
  }
}

.tabbed-carousel-50-50__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;

  @include phone {
    padding-bottom: .5rem;
  }
}

.image-carousel-50-50__content-image {
  max-width: rem(800);
  height: 30rem;
  margin: 0 1rem;

  @include tablet {
    width: 100%;
    margin: 1.5rem 0 2.5rem 0;
  }

  @include phone {
    max-height: 20rem;
    margin: 1rem 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: rem(4);
  }
}

.image-carousel-50-50__content-text {
  width: 27rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
  gap: rem(35);

  h2 {
    font-family: $heading-font-family;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3.125rem;
    letter-spacing: .05rem;
    color: $color-brand;
    text-align: center;
    margin: 0;
    padding: .1rem;

    @include tablet {
      line-height: 1.5rem;
      font-size: 1.875rem;
    }

    @include phone {
      font-size: 1.2rem;
    }
  }

  p {
    color: $color-black;
    font-family: $text-font-family;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;

    @include phone {
      font-size: .875rem;
    }
  }

  @include tablet {
    width: 100%;
    height: auto;
    margin: 0;
  }

}

.image-carousel-50-50__content-tabs {
  display: flex;
  justify-content: center;
}

.image-carousel-50-50__custom-dots {
  display: flex;
  justify-content: center;
  gap: rem(10);
}

.image-carousel-50-50__custom-dot,
.carousel__dot {
  width: rem(11);
  height: rem(11);
  border-radius: 50%;
  background-color: $color-star-jasmine;
  border: none;
  cursor: pointer;
  padding: 0;

  &--selected {
    background-color: $color-brand;
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.alert {
  &--page {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
    align-items: center;
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    padding: 1rem 1.75rem;
  }

  &__icon {
    font-size: 1rem;
    border-radius: 2rem;
    padding: 1rem;
    color: $color-white;
    width: 1rem;
    text-align: center;
  }

  &__text {
    font-family: $text-font-family;
    font-weight: bold;
  }

  &__close {
    cursor: pointer;
    color: $color-black;
  }

  &__link {
    display: inline-block;
    color: $color-brand;
    border-bottom: solid rem(1) $color-avocado-green;
    padding-bottom: 0.1rem;
    margin-left: 1rem;

    @include phone {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }
}

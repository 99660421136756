@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.focus-carousel {
  position: relative;

  &__header {
    margin-bottom: rem(30);

    h3 {
      color: $color-brand;
      font-family: $heading-font-family;
      font-family: Bitter;
      font-size: rem(40);
      font-style: normal;
      font-weight: 500;
      line-height: rem(50);
      letter-spacing: rem(0.8);
      text-transform: capitalize;
    }

    &--inner {
      max-width: rem(700);
    }

    &--empty {
      flex-shrink: 0;
      width: rem(450);
    }
  }

  &__imgWrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    border-radius: rem(8);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 27.08%, #000 78.65%);
      z-index: 1;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    color: $color-white;
    z-index: 2;
    display: grid;
    place-items: center;
    width: 100%;

    h4,
    p {
      padding: 0 rem(30);
    }

    h4 {
      font-family: $heading-font-family;
      font-size: rem(40);
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      text-transform: capitalize;
      letter-spacing: rem(0.8);
      text-align: center;
    }

    .focus-carousel__descriptionWrapper {
      padding: 0 rem(30);
    }

    .rte p {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 300;
      line-height: 175%;
    }

    .rte {
      margin-bottom: rem(40);
      z-index: 5;
    }
  }

  &__highlighted {

    .carousel__slide {
      width: rem(450) !important;
      margin-left: calc((#{rem(450)} - 12.5%) * -1);
      z-index: 2;
      position: fixed;
      left: 85%;

    }

    .focus-carousel__slide {
      height: rem(700);
      transform: scale(.9);
      transition: transform 0.5s ease;
    }

    .focus-carousel__content {
      bottom: rem(45);
    }
  }

  &__normal {

    .focus-carousel__imgWrapper {
      height: rem(300);
      margin-right: rem(10);
    }

    .focus-carousel__slide {
      height: rem(300);
      margin-top: rem(350);
      transform: scale(1);
      transition: transform 0.5s ease;
    }

    .focus-carousel__content {
      bottom: rem(35);

      h4,
      p {
        display: none;
      }
    }

    .focus-carousel__button--centered {
      margin-right: rem(10);
    }
  }

  &__controls {
    position: absolute;
    bottom: rem(45);
    left: rem(35);
    right: rem(35);
    z-index: 2;

    &Wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
    }
  }

  &__button {
    cursor: pointer;
    background: none;
    border: none;

    &--centered {
      z-index: 1;
    }

    &--hidden {
      opacity: 0.3;
      pointer-events: none;
    }

    &--back,
    &--next {
      svg {
        path {
          fill: $color-brand !important;
        }
      }
    }
  }

  &__custom-dots,
  &__dots {
    display: flex;
    justify-content: center;
    gap: rem(5);
    margin-top: rem(65);

    .focus-carousel__custom-dot,
    .carousel__dot {
      width: rem(11);
      height: rem(11);
      border-radius: 50%;
      background-color: #D8DCE6;
      border: none;
      cursor: pointer;
      padding: 0;

      &--selected {
        background-color: $color-brand;
      }
    }
  }

  &__desktop {
    display: none;

    .focus-carousel__slider {
      height: rem(700); // Set slider height to match the tallest card
      position: relative;
      overflow: hidden; // Ensure controls aren't cropped
    }

    // Default slide style
    .focus-carousel__slide {
      position: sticky;
      flex: 0 0 auto; // To prevent flex items from stretching
    }
  }

  &__mobile {
    display: block;
    width: 100%;

    .focus-carousel__slideWrapper {
      height: 100%;
      width: 100%;
      // max-width: rem(550); <-- slide wrapper is set up in a way so you can set a max-width here if you want
      margin: 0 auto; // and it will be centered <3
      position: relative;
    }

    .carousel__slider {
      width: 100%;
    }

    .carousel__inner-slide {
      height: rem(635);
      min-width: rem(318);
    }

    .focus-carousel__slider {
      height: rem(635); // Set slider height to match the tallest card
    }

    .focus-carousel__imgWrapper {
      margin-right: rem(10);
    }

    .focus-carousel__content {
      position: absolute;
      bottom: 0;
    }

    .focus-carousel__buttonContainer {
      display: flex;
      justify-content: center;
      margin-bottom: rem(45);
    }
  }

  &__desktop {
    display: none;
  }

  @include after-tablet {
    &__mobile {
      display: none;
    }

    &__desktop {
      display: block;
    }

    &__header {
      padding: 0 rem(100);
      margin: 0;
      height: rem(300);
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;

      &--inner {
        padding-right: 5%;
        padding-left: 5%;
      }
    }

    &__imgWrapper {
      height: 100%;
    }
  }

  .rte {
    p {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
    }
  }
}
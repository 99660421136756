@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.three-column-image-carousel {
  &__slider {
    height: rem(270); // Mobile height
  }

  &__img-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden; // hides any overflow

    img {
      position: relative; // to ensure the overlay appears over the image
      z-index: 1; // to ensure the overlay appears over the image
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50));
    z-index: 2; // higher than the image
    border-radius: rem(8);
  }

  &__center,
  &__adjacent {
    height: rem(270);
    width: rem(318);
  }

  &__adjacent {
    width: 100%;
  }

  &__controls {
    display: flex;
    align-items: center;
    margin: rem(50) 0;
    width: 100%;
    justify-content: center;
  }

  &__dots {
    margin: rem(40) 0;
  }

  &__custom-dots,
  &__dots {
    display: flex;
    justify-content: center;
    gap: rem(5);

    .carousel__custom-dot,
    .carousel__dot {
      width: rem(11);
      height: rem(11);
      border-radius: 50%;
      background-color: #D8DCE6;
      border: none;
      cursor: pointer;

      &--selected {
        background-color: $color-brand;
      }
    }
  }

  &__button {
    &--back, &--next {
      border: none;
      padding: 0;
    }

    &--back {
      margin-right: rem(115);
    }

    &--next {
      margin-left: rem(115);
    }
  }

  &__desktop {
    display: none; // Initially hidden
  }

  &__mobile {
    display: block;

    .carousel__inner-slide {
      height: rem(270);
      min-width: rem(318);
      max-width: rem(600);
    }
  }

  @include after-phone {
    &__slider {
      height: rem(450);
    }

    &__img-wrapper {
      margin-left: rem(5);
      margin-right: rem(5);
    }

    &__center {
      height: rem(450);
      width: rem(500);
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    &__adjacent {
      margin-top: rem(90);
      z-index: 1;
    }

    &__desktop {
      display: block;
    }

    &__mobile {
      display: none;
    }
  }

  @include after-tablet {
    &__slider {
      height: rem(508);
    }

    &__center {
      height: rem(508);
      width: rem(600);
    }

    &__adjacent {
      height: rem(319);
      margin-top: rem(94.5);
    }
  }
}

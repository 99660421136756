@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";
@import 'shared/assets/stylesheets/core.scss';

.h1-image {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: rem(20);
  padding: rem(10) rem(15.008);
  max-width: 100%;

  &__description {
    width: 100%;
    margin-top: rem(40);

    .component__margin {
      margin: 0;
    }

    .container {
      padding: 0;
    }
  }

  &__carousel {
    width: 100%;

    &__content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(15);
      border-radius: rem(8);
      overflow: hidden;
      height: rem(300);
    }

    &__content {
      max-width: rem(300);
    }

    &__navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(45);
      width: 100%;
      margin-bottom: rem(30);
    }

    &__dot-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(45.008);
    }

    &__btn-right,
    &__btn-left {
      display: none;
    }

    &__dot {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(45.008);
    }

    .carousel__dot {
      border-radius: 50%;
      width: rem(12);
      height: rem(12);
      border: none;
      box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, .2);
      margin: .4rem;
    }

    .carousel__dot--selected {
      background-color: $color-brand;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border-radius: rem(8);
    }

    h5 {
      width: rem(301);
      color: $color-brand;

      text-align: center;
      font-family: $text-font-family;
      font-size: rem(14);
      font-style: normal;
      font-weight: 600;
      line-height: rem(25);
      letter-spacing: rem(2.1);
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  @include after-tablet {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: rem(100);

    &--row-reverse {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
    }

    &__description {
      width: 50%;
    }

    &__carousel {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: rem(22);

      &__content-container {
        display: block;
        max-width: 100%;
      }

      &__content {
        max-width: rem(500);
      }

      &__navigation {
        margin-bottom: rem(10);
      }

      &__btn-right,
      &__btn-left {
        display: block;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-width: inherit;
        border-radius: rem(8);
      }

      .carousel__dot {
        border-radius: 50%;
        width: rem(16);
        height: rem(16);
        border: none;
        box-shadow: rem(0) rem(2) rem(8) rem(0) rgba(0, 0, 0, .2);
        margin: rem(6.4);
      }

      .carousel__dot--selected {
        background-color: $color-brand;
      }
    }
  }
}
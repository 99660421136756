@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.text-intro-two-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 1rem;

  @include tablet {
    margin: 2rem 2rem;
  }

  @include phone {
    margin: 1.5rem 2rem;
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.previewPopUp {
    width: rem(300);
    position: fixed;
    bottom: rem(30);
    left: rem(30);
    border-radius: rem(10);
    z-index: 10000;

    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.6);

    &__content {
        background-color: #fff;
        border-bottom-left-radius: rem(10);
        border-bottom-right-radius: rem(10);
        padding: rem(20);
    }

    &__header1 {
        display: flex;
        justify-content: center;
        padding: rem(10);
        background-color: rgb(73, 69, 255);
        border-top-left-radius: rem(10);
        border-top-right-radius: rem(10);

        svg {
            path {
                fill: #fff;
            }
        }
    }

    &__header2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(20);
    }

    &__close {
        &:hover {
            cursor: pointer;
            scale: 1.2;
        }
    }

    &__indicator {
        height: rem(10);
        width: rem(10);
        border-radius: 50%;

        &--active {
            background-color: $color-impact-green;
        }

        &--inactive {
            background-color: $color-red;
        }
    }

    p {
        font-family: $text-font-family;
    }

    &__countdown {
        text-align: center;
        font-size: rem(40);
        margin-bottom: rem(20);
    }

    &__logo {
        width: rem(50);
        position: absolute;
        bottom: rem(20);
        left: rem(20);
    }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/core.scss';

.featured-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(68);
  gap: rem(40);

  &__desktop {
    display: none;
  }

  &__mobile {
    max-width: 100%;

    &__carousel {

      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__content {
          &__image {
            background-size: cover;
            background-position: center center;
            width: rem(350);
            max-width: 100%;
            height: rem(371);
            border-radius: rem(8) rem(8) rem(0) rem(0);
            display: flex;
            align-items: end;

            h2 {
              max-width: rem(299);
              max-height: rem(274);
              color: $color-white;
              font-family: $heading-font-family;
              font-size: rem(36);
              font-style: normal;
              font-weight: 500;
              line-height: rem(50);
              letter-spacing: rem(0.72);
              text-transform: capitalize;
              padding-left: rem(25);
              margin-bottom: rem(30);
            }
          }

          &__text {
            display: flex;
            padding: rem(30) rem(25);
            flex-direction: column;
            align-items: flex-start;
            gap: rem(15);
            border-radius: rem(0) rem(0) rem(8) rem(8);
            width: rem(302);
            max-width: 84%;

            h3 {
              max-width: rem(300);
              color: $color-brand;
              font-family: $text-font-family;
              font-size: rem(24);
              font-style: normal;
              font-weight: 600;
              line-height: rem(34);
              text-transform: capitalize;
              margin: 0;
            }

            h5 {
              max-width: rem(299);
              height: rem(40);
              flex-shrink: 0;
              color: $color-brand;
              font-family: $text-font-family;
              font-size: rem(14);
              font-style: normal;
              font-weight: 600;
              line-height: rem(24);
              letter-spacing: rem(2.1);
              text-transform: uppercase;
              margin: 0;
            }

            p {
              color: $color-black;
              max-width: rem(299);
              font-family: $text-font-family;
              font-size: rem(14);
              font-style: normal;
              font-weight: 400;
              line-height: rem(24);
            }
          }
        }
      }
    }
  }

  @include after-tablet {

    &__mobile {
      display: none;
    }

    &__desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin: 0 auto;
      position: relative;

      &__carousel-container {
        display: flex;
        margin: 0 rem(30);
        overflow: hidden;
        height: auto;
        width: 100vw;

        .featured-card__desktop__carousel {
          width: 100%;
          height: auto;

          .slide-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            &__image {
              flex-basis: 70%;
              height: 100%;
              background-size: cover;
              background-position: center;
              border-radius: rem(8) rem(0) rem(0) rem(8);


              h2 {
                position: absolute;
                bottom: 1%;
                left: 2%;
                width: 58%;
                max-width: rem(721);
                color: $color-white;
                font-family: $heading-font-family;
                font-size: rem(40);
                font-style: normal;
                font-weight: 500;
                line-height: rem(60);
                letter-spacing: rem(0.8);
                text-transform: capitalize;
              }
            }

            &__text {
              flex-basis: 30%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: rem(0) rem(31.6);
              height: 100%;
              border-radius: rem(0) rem(8) rem(8) rem(0);
              gap: rem(10);

              &__button {
                padding-bottom: rem(20);
              }

              h3 {
                max-width: rem(300);
                color: $color-brand;
                font-family: $text-font-family;
                font-size: rem(24);
                font-style: normal;
                font-weight: 600;
                line-height: rem(34);
                text-transform: capitalize;
                margin: 0;
                padding-top: rem(20);
              }

              h5 {
                max-width: rem(299);
                height: rem(40);
                flex-shrink: 0;
                color: $color-brand;
                font-family: $text-font-family;
                font-size: rem(14);
                font-style: normal;
                font-weight: 600;
                line-height: rem(24);
                letter-spacing: rem(2.1);
                text-transform: uppercase;
                margin: 0;
              }

              p {
                color: $color-black;
                max-width: rem(299);
                font-family: $text-font-family;
                font-size: rem(14);
                font-style: normal;
                font-weight: 400;
                line-height: rem(24);
              }
            }
          }
        }
      }
    }
  }
}
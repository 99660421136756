@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";
@import 'shared/assets/stylesheets/core.scss';

.image-scroller {
  max-width: 100%;

  .component__margin {
    // copy block's margin
    margin: 0 0 rem(90)
  }

  .container {
    width: 100%;
  }

  &__slide-indicator {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: rem(40);
    gap: rem(30);
  }

  &__custom-dots {
    display: flex;
    gap: rem(5);
  }

  &__custom-dot,
  .carousel__dot {
    width: rem(11);
    height: rem(11);
    border-radius: 50%;
    background-color: #D8DCE6;
    border: none;
    cursor: pointer;
    padding: 0;

    &--selected {
      background-color: $color-brand;
    }
  }

  .carousel_dot-group,
  &__dots {
    display: flex;
    gap: rem(5);
  }

  &__left-arrow,
  &__right-arrow {
    display: none;
  }

  &__desktop {
    display: none;
  }

  &__mobile {

    &__carousel {
      height: rem(475);
    }

    &__slider-tray {
      gap: rem(20);
      max-width: max-content;
    }

    &__inner-slide {
      max-width: rem(340);
      box-sizing: border-box;
      position: relative;
    }

    &__content {
      height: fit-content;
      box-sizing: border-box;
      position: relative;

      &__button-overlay {
        position: absolute;
        bottom: rem(10);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    img {
      max-width: 100%;
      max-height: rem(380);
      object-fit: cover;
      border-radius: rem(10);
    }
  }

  @include after-phone {
    &__mobile {
      display: none;
    }

    &__desktop {
      display: block;

      &__carousel {
        height: rem(620);
        overflow: hidden;
      }

      &__slider-tray {
        gap: rem(20);
        max-width: max-content;
      }

      &__inner-slide {
        min-width: rem(370);
        position: relative;
      }

      &__content {
        box-sizing: border-box;
        position: relative;

        &__button-overlay {
          position: absolute;
          bottom: rem(10);
          left: 50%;
          transform: translateX(-50%);
        }
      }

      img {
        max-width: 100%;
        height: rem(480);
        object-fit: cover;
        border-radius: rem(10);
      }
    }
  }
}

.copy-block {
  &--intro {

    div:nth-child(3) {
      text-align: -webkit-center;
    }
  }
}
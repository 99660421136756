@import 'shared/assets/stylesheets/core.scss';
@import 'shared/assets/stylesheets/mixins.scss';

.popupModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
		background: transparent;
		border: none;
		overflow: hidden;

		&::backdrop {
			background-color: rgba($color-brand, 0.75);
		}

    &[open] {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
    }

    &__content {
      background-color: white;
      position: relative; 
      border-radius: rem(8);
			max-width: rem(775);

			// Inner scrollable content div
			&Wrapper {
				overflow-y: auto;
				max-height: calc(100vh - 140px);  // adjust this for max viewport height minus top and bottom padding
				width: 100%;
				max-width: rem(775);
			}

			&Spacing {
				padding: 0 rem(20) 0 rem(20);
			}
    }

		&__close {
			display: flex;
			justify-content: flex-end;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			font-size: rem(28);
			padding: rem(20) rem(30) rem(20) rem(20);
			width: 100%;

			path {
				fill: $color-brand;
			}
		}

		@include after-tablet {
			&__content {
				padding: rem(90) rem(75) rem(45) rem(50);
			}
	
			&__close {
				position: absolute; 
				top: rem(32);
				right: rem(32);
				margin: 0;
				width: auto;
				padding: 0;
			}
		}
  }
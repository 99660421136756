@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/core.scss';

.h1-intro-one-column {
  align-items: center;
  flex-direction: column;

  @include after-tablet {
    flex-direction: row;
  }
}

.volunteer {
  &--mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    min-height: rem(323);
    padding-bottom: rem(30);

    &__entry {
      padding: rem(15) rem(15) rem(5) rem(15);
      border: rem(2) solid $color-smoke;
      background: $color-white;

      &:nth-child(2n) {
        background-color: $color-smoke;
      }
    }



    &__details-container {
      display: flex;
      flex-direction: column;
      gap: rem(10);
    }

    &__details {
      display: flex;
      padding-bottom: rem(20);
    }

    &__content {
      width: rem(130);
      color: $color-black;
      font-family: $inter-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(21);
    }

    &__label {
      width: rem(130);
      color: $color-black;

      font-family: $inter-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 600;
      line-height: rem(21);
      font-weight: 700;
    }
  }

  &--desktop {
    display: none;
  }

  @include after-tablet {

    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;

      table {
        max-width: rem(1087);
        margin-left: auto;
        margin-right: auto;
        border: rem(2) solid $color-smoke;
      }

      thead {
        text-align: left;
        color: $color-black;
        font-family: $inter-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: rem(28);
        background: $color-smoke;
        height: rem(44);
      }

      th {
        vertical-align: middle;
        padding: rem(0) rem(30) rem(0) rem(30);
      }

      tbody {
        color: $color-black;
        font-family: $inter-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);

        tr {
          &:nth-child(2n) {
            background-color: $color-smoke;
          }
        }
      }

      td {
        width: rem(203);
        padding-left: rem(30);
        padding-top: rem(25);
        padding-bottom: rem(25);
      }
    }
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.footer {
  min-height: rem(620);
  background-color: $color-brand;
  color: $color-white;
  padding: 0 rem(150) 2rem rem(150);
  line-height: 1.2rem;

  h6 {
    font-size: rem(14);
    font-weight: bold;
    margin-bottom: rem(15);
    width: fit-content;
  }

  @include tablet {
    padding: 1rem;
    height: auto;
  }

  &__header {
    padding: 4rem 0 2rem 0;
    display: flex;
    justify-content: space-between;
    font-family: $text-font-family;

    @include tablet {
      flex-direction: column;
      align-items: center;
    }

    .actions {
      display: flex;
      align-items: center;
      width: 100%;
      gap: rem(50);

      .button {
        min-width: rem(128);

        @include tablet {
          min-width: auto;
          // margin-top: 2rem;
        }
      }
    }

    .footer-nav {
      ul {
        display: flex;
        gap: 2rem;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr auto;
    font-family: $text-font-family;
    padding: 2rem 0;
    grid-gap: 2rem;

    @include tablet {
      grid-template-columns: 1fr;
    }

    .sub-nav-groups {
      display: inline-grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1rem;

      @include tablet {
        grid-template-columns: 1fr;
      }

      ul {
        text-align-last: left;
        gap: 2rem;

        li {
          margin-bottom: rem(12);
          font-size: rem(13);
        }

        :last-child {
          margin-bottom: 0;
        }
      }

      &--border-right {
        border-right: solid rem(1) #fff;
        padding-right: 1rem;

        @include tablet {
          border-right: none;
          padding-right: 0;
        }
      }

      &--vertical {
        display: block;

        @include tablet {
          border-top: solid rem(1) #fff;
          padding-top: 2rem;
        }

        >* {
          margin-bottom: 2rem;
        }

        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__copyright {
    font-family: $inter-font-family;
    font-size: rem(14);
    border-top: solid rem(1) $color-white;
    padding-top: 1rem;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: left;
    gap: rem(15);

    ul {
      text-align-last: left;
      gap: 2rem;

      li {
        margin-bottom: rem(12);
        font-size: rem(13);
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    .nav--social {
      display: inline-flex;
      justify-content: end;

      ul {
        display: flex;
        align-self: center;

        li {
          transform: scale(1);
          transition: transform .5s ease;

          &:hover {
            transform: scale(1.2);
            animation: .1s forwards ease;
          }
        }
      }

      @include tablet {
        margin: 2rem 0;
        justify-content: center;
      }
    }

    .button--btn-icon {
      color: $color-white;
    }

    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
  }
}
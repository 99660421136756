$page-gutters: 9.375rem;
$color-brand: #002957;
$color-navy: #002957;
$color-bayern-blue: #367bc7;
$color-green: #9FCC3B;
$color-matcha-green: #c9ef74;
$color-white: #FFF;
$color-solitude: #a6aab3;
$color-black: #000000;
$color-teal: #245F7A;
$color-river-counties-blue: #245F7A;
$color-river-counties-light-blue: #D4EBF5;
$color-dusty-blue: #6B80AA;
$color-pacific-blue: #00A4C7;
$color-malibu-blue: #7AD0E2;
$color-blue: #7AD0E2;
$color-light-blue: #E4F6F9;
$color-weldon-blue: #8094AB;
$color-jade-green: #0CA276;
$color-avocado-green: #9ECB3B;
$color-forest-green: #006647;
$color-gloucester-green: #006647;
$color-watermelon-red: #EE4E66;
$color-pomegranate-red: #A2216A;
$color-mathews-pink: #A2216A;
$color-pumpkin-orange: #FB7027;
$color-orange: #FB7027;
$color-bubbles: #E4F6F9;
$color-azureish-white: #E0E6F2;
$color-light-periwinkle: #E0E6F2;
$color-dark-blue-gray: #62759C;
$color-periwinkle: #62759C;
$color-beige: #F0F9DC;
$color-grass: #9FCC38;
$color-light-grass: #F0F9DC;
$color-gray: #BFBFBF;
$color-ephemeral-blue: #ccd4dd;
$color-light-green: #ECF5D8;
$color-impact-green: #009579;
$color-maroon: #82235F;
$color-powder: #B9DBE5;
$color-sapphire: #005CB9;
$color-soft-gray: #D8DFE1;
$color-solid-gray: #888;
$color-placeholder-text: rgba(107, 107, 107, 1);
$color-yellow: #E6CF01;
$color-orange-light: #FEE2D4;
$color-light-orange: #FEE2D4;
$color-star-jasmine: #D8DCE6;
$color-illusion-blue: #CCD4DD;
$color-gloucester-light-green: #CFF6EB;
$color-mathews-light-pink: #F4D1E5;
$color-red: #FF0000;
$color-smoke: #F1F1F1;
$color-space-gray: #8A8A8A;
$color-moon-gray: #7C7B7B;
$color-blue-lolite: #5576c3;
$color-dark-charcoal: #333;
$color-gainsboro: #e6e6e6;
$color-alice-blue: #eaf6ff;
$color-disabled-pagination: rgba(16, 16, 16, 0.3);
$heading-font-family: 'Bitter';
$text-font-family: 'Open Sans';
$inter-font-family: 'Inter';

// Font awesome chevron icon data urls. Used for background images in elements like the scrollbar
$arrow-up: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'><path d='M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z'/></svg>";
$arrow-down: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'><path d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/></svg>";
$arrow-left: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='white'><path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'/></svg>";
$arrow-right: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='white'><path d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/></svg>";

$radialImage: url("/shared/assets/images/radial-white.png");
$logo-transparent: url("/shared/assets/images/logo-transparent.png");
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.menu {
  &--mega {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-column: 1/3;
    row-gap: 1rem;

    &WithSearch {
      margin-top: rem(20);
    }

    .bare {
      display: inline-flex;
      justify-self: end;
      background: none;
      border: 0;
      font-family: $heading-font-family;
      font-size: 0.8750rem;
      grid-column: 2;
      padding-right: 5rem;

      li {
        padding: 0 2rem;

        a {
          font-size: 1rem;

          &:hover {
            padding-bottom: 0.1rem;
            border-bottom: solid 0.1250rem $color-green;
          }
        }
      }
    }

    @include tablet {
      display: none;
    }
  }

  &__left {
    padding: 2rem 3rem;
    font-family: $text-font-family;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: rem(256);

    .title {
      font-family: $text-font-family;
      font-size: rem(23);
      font-weight: 600;
      text-align: center;
      line-height: 125%;
      margin-bottom: rem(20);
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }

    &Button {
      min-width: 8rem;
      background-color: $color-white;
      color: $color-brand !important;
      font-weight: 700;
    }

    &Socials {
      margin-top: rem(40);

      ul {
        display: flex;
        gap: rem(35);
      }

      .button {
        border: none;
      }
    }
  }

  &__right {
    display: flex;
    padding: 2rem 2.5rem;
    align-self: center;
    justify-content: center;
    font-family: $text-font-family;
    gap: 2rem;
    width: -webkit-fill-available;
    min-height: rem(256);
    background-color: $color-white;
    line-height: 16pt;

    &:has(.no-lists) {
      align-items: center;
    }

    .no-lists {
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: 2rem;
    }

    @include tablet {
      flex-direction: column;
    }
  }

  &__list-item {
    h4 {
      letter-spacing: rem(2.4);

      a {
        color: $color-brand;
      }
    }

    ul {
      color: $color-black;

      li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    a {
      color: $color-black;
    }
  }
}
@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";

.image-cards {
  display: flex;
  max-width: 100%;
  height: max-content;
  justify-content: flex-start;
  gap: rem(15);
  overflow-x: auto;

  &__card--alt,
  &__card {
    position: relative;
    width: fit-content;
    height: rem(600);
    max-width: rem(300);
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.00) 29.69%,
          #000000 76.56%);
      z-index: 2;
      border-radius: rem(8);
    }

    &__content {
      position: absolute;
      bottom: 2%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: rem(15);
      z-index: 3;
      margin: rem(30);
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      z-index: 1;
      border-radius: rem(8);
    }

    h2 {
      color: $color-white;
      text-align: center;
      font-family: $heading-font-family;
      font-size: rem(36);
      font-style: normal;
      font-weight: 500;
      line-height: rem(46);
      letter-spacing: rem(0.72);
      text-transform: capitalize;
    }

    p {
      color: $color-white;
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(28);
      padding: 0 rem(10);
    }
  }

  &__card--alt {

    &::before {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.00) 20.31%,
          #000 100%)
    }

    img {
      border-radius: rem(8);
    }
  }

  &--image-cards-two,
  &--image-cards-three {

    >.image-cards__card,
    >.image-cards__card--alt {
      max-width: rem(348.998);
    }
  }

  @include after-tablet {
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: center;

    &--image-cards-two,
    &--image-cards-three {
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: center;

      >.image-cards__card,
      >.image-cards__card--alt {
        max-width: rem(457);
        flex-shrink: 1;
      }
    }

    &--image-cards-two {

      >.image-cards__card,
      >.image-cards__card--alt {
        max-width: rem(699);
      }
    }
  }
}
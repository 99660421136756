@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

body {
  header {
    width: 100%;
    display: grid;
    position: absolute;
    min-height: 7.8125rem;
    grid-template-columns: minmax(auto, 22.8125rem) 1fr;
    column-gap: 15%;
    justify-content: space-between;
    z-index: 999;
    /* one beneath any modals */
    top: 0;

    @include tablet {
      background-color: $color-brand;
      overflow: hidden;
      display: unset;
      min-height: auto;
    }

    #nav_actions {
      background-color: unset;
      padding: unset;
      grid-gap: unset;

      #menu_btn {
        display: none;
      }

      @include tablet {
        display: grid;
        grid-template-columns: minmax(auto, rem(150)) auto;
        grid-gap: 1rem;
        min-height: auto;
        background-color: $color-brand;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        padding: 2.5rem 1rem 1rem 1rem;

        #menu_btn {
          display: block;
        }
      }

      .logo {
        display: block;
        padding: 1rem 0 0 1rem;
        max-width: 20.8125rem;
        grid-column: 1;

        @include tablet {
          padding: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    #menu_btn {
      grid-column: 2;
    }

    nav {
      color: $color-white;
      font-family: $text-font-family;

      &.desktop-expanded {
        height: 100%;
      }

      &.desktop {
        display: inline-flex;
        flex-flow: column;
        padding-right: 2rem;
        justify-self: end;
        grid-column: 2;
        position: absolute;

        .nav__row--colors {
          .fund-advisor {
            a {
              background-color: $color-malibu-blue;
            }
          }

          .no-radius {
            border-radius: none;
          }
        }

        .nav__row--search {
          cursor: pointer;
        }

        @include tablet {
          display: none;
        }

        ul+.sub-nav {
          border-radius: 1rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          height: 100%;
        }

        ul:has(~ .sub-nav:not(.hide):not(.radius)) {
          border-radius: 0;
        }

        .sub-nav--nested {
          background-color: $color-light-periwinkle;

          a {
            color: $color-brand;
          }
        }

        >ul {
          display: inline-block;
          margin: 0;
          list-style: none;
          background-color: $color-brand;
          color: $color-white;
          font-family: $text-font-family;
          border-radius: 1rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          font-size: 0.8125rem;
          width: -webkit-fill-available;
          min-width: 38rem;
          padding: 0 3rem;

          li {
            display: inline-block;

            a {
              padding: 1.5rem 1rem;
              display: inline-block;

              &:hover {

                //border-bottom: solid 0.1250rem $color-green;
                span {
                  padding-bottom: 0.1rem;
                  border-bottom: solid 0.1250rem $color-green;
                }
              }
            }
          }
        }
      }

      &.desktop.hide {
        height: auto;
      }

      &.mobile {
        display: none;
        grid-row: 2;
        width: 100%;
        grid-column: 1/3;

        @include tablet {
          display: block;
        }

        .navbars {
          a {
            width: -webkit-fill-available;
          }

          h3 {
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 1rem;
            letter-spacing: 0.15rem;
            margin-bottom: 0;
          }

          .start-fund {
            display: block;
            padding: 2.4rem 1.875rem;
          }

          .navbars__donate {
            grid-template-columns: 1fr 1fr;
            display: grid;
            justify-items: center;
            text-align: center;

            .donate {
              color: $color-brand;
            }

            >a {
              padding: 1.25rem 0;
            }
          }

          .navbars__sub {
            background-color: $color-white;
            color: $color-black;
            padding: 1.5rem 0;

            ul {
              li {
                margin: 0 2rem;
                border-radius: unset;
                border-bottom: solid rem(1) $color-illusion-blue;

                a {
                  display: flex;
                  justify-content: space-between;
                  border: 0;
                  font-family: $heading-font-family;
                  font-size: rem(20);
                  font-weight: 600;
                  letter-spacing: rem(0.4);
                  padding: 1rem;
                }

                &:last-of-type {
                  border-bottom: none;
                }
              }
            }
          }

          .navbars__accordion {
            display: flex;
            flex-direction: column;

            :nth-child(4) {
              order: 1;

              .accordion {
                border-bottom: none;
              }

              svg {
                padding-right: rem(10);
              }
            }

            .accordion {
              border-bottom: solid rem(2) $color-dusty-blue;
              border-radius: 0;

              h3 {
                font-family: $text-font-family;
                font-weight: 600;
                font-size: 1rem;
                letter-spacing: rem(2.4);
              }
            }

            .accordion__container__bottom {
              background-color: $color-illusion-blue;
              color: $color-brand;
            }
          }
        }
      }
    }
  }
}

.navbar-icon {
  margin-right: rem(8);
}
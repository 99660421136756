.video-player {
  position: relative;
  width: 100%;
  // This padding-top value is for a 16:9 aspect ratio. Adjust as needed for different ratios.
  padding-top: 56.25%; // Aspect ratio: (9 / 16) * 100%
  overflow: hidden;
  background-color: black; // Optional, for a nicer look before the video loads.

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--vimeo {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/core.scss';

.infographic-standard,
.infographic-standard--carousel {
  display: flex;
  flex-direction: column;
  gap: 5.75rem;
  height: 100%;
  max-width: 100%;

  @include phone {
    gap: 1.875rem;
    max-width: 100%;
  }

  &--carousel {
    gap: 2.75rem;
  }

  &__overview {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      width: 58.75rem;
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 3.125rem;
      letter-spacing: 0.05rem;
      text-transform: capitalize;

      @include phone {
        width: 18.75rem;
        align-self: center;
      }
    }
  }

  &__overview--carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.688rem;

    @include phone {
      justify-content: unset;
    }

    h1 {
      color: $color-brand;
      text-align: center;
      font-family: $text-font-family;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.563rem;
      letter-spacing: 0.131rem;
      text-transform: uppercase;

      @include phone {
        width: 18.75rem;
        align-self: center;
      }
    }
  }

  &__bar-container {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    max-width: 100%;

    @include tablet {
      flex-wrap: wrap;
    }

    @include phone {
      width: fit-content;
      flex-wrap: wrap;
    }
  }

  h2 {
    width: 2.813rem;
    font-family: $heading-font-family;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.125rem;
    letter-spacing: 0.05rem;
    text-transform: capitalize;
    border-bottom: 0.25rem solid;
    padding-bottom: 1.25rem;
    margin-bottom: 0rem;
  }

  h5 {
    width: 8.75rem;
    font-family: $text-font-family;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    padding-top: 1.25rem;

    @include phone {
      width: 7.5rem;
    }
  }

  p {
    width: 58.75rem;
    color: $color-black;
    font-family: $text-font-family;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;

    @include phone {
      width: 18.75rem;
      align-self: center;
    }
  }

  &__large_image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    background-image: url('../../../shared/assets/images/transparent_logomark\ 1.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 33.688rem;
    height: 13.563rem;
    border-radius: 0.5rem;

    @include phone {
      width: -webkit-fill-available;
      background-size: 15.625rem 13.75rem;
      margin: rem(0) rem(3);
    }
  }

  &__small {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    width: 16.5rem;
    height: 13.563rem;
    border-radius: 0.5rem;

    @include phone {
      width: 48.21%;
    }
  }

  &__large {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    align-items: flex-start;
    width: 33.688rem;
    height: 13.563rem;

    @include phone {
      width: -webkit-fill-available;
      margin: rem(0) rem(3);
    }
  }

  &__box-avocado-green--carousel,
  &__box-dark-blue-gray--carousel,
  &__box-malibu-blue--carousel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11.313rem;
    height: 16.25rem;
    border-radius: 0.5rem;
  }

  &__box-avocado-green--carousel,
  &__box-dark-blue-gray--carousel,
  &__box-malibu-blue--carousel {
    @include tablet {
      overflow: hidden;
      width: 9.375rem;
    }

    @include phone {
      width: 9.063rem;
    }
  }

  &__box-malibu-blue--carousel {
    color: $color-white;
    background-color: $color-malibu-blue;
  }

  &__box-avocado-green--carousel {
    color: $color-brand;
    background-color: $color-avocado-green;
  }

  &__box-dark-blue-gray--carousel {
    color: $color-white;
    background-color: $color-dark-blue-gray;

    @include tablet {
      width: 9.688rem;
    }
  }

  &__content {
    width: fit-content;
    padding: 1.25rem 1.25rem 0rem 1.875rem;

    @include tablet {
      padding: 0.25rem 0.25rem 0rem 0.875rem;
    }

    @include phone {
      padding: 0.25rem 0.25rem 0rem 0.85rem;
    }
  }
}

.fontColor {
  &--navy {
    color: $color-brand
  }

  &--white {
    color: $color-white
  }
}
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

body:has(.error-page) {
  header {
    position: relative;
    background-color: $color-brand;
    padding-bottom: 1.25rem;

    @include tablet {
      position: absolute;
    }
  }

  .error-page {
    text-align: center;

    @include tablet {
      margin-top: 5rem;
    }

    &__message {
      padding: 6rem 3rem;
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        border-bottom: solid rem(1) $color-illusion-blue;
        padding-bottom: 3rem;
        margin-bottom: 4rem;
      }

      h2 {
        font-family: $heading-font-family;
        font-size: rem(40);
      }

      p {
        font-family: $text-font-family;
        font-size: rem(24);
      }
    }
  }
}

@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.tabbed-nav {
  z-index: 998; // 1 higher than hero and 1 less than nav
  position: relative;

  &__desktop {
    display: none;
  }

  &__main {
    display: grid;
    grid-auto-flow: column; // Keeps items in a single row
    grid-auto-columns: minmax(min-content, 270px); // Allows items to shrink below 270px if necessary
    overflow-x: auto; // Enables horizontal scrolling if items can't shrink enough
    width: 100%;
    justify-content: center;
    gap: rem(20);
  }

  &__item,
  &__sub-item {
    font-family: $heading-font-family;
    border: none;
    background-color: transparent;
    color: darken($color-azureish-white, 40%); // Dimmed color
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $color-azureish-white;
    }
  }

  &__item,
  &__item--active {
    background-color: $color-dark-blue-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(28);
    border-top-left-radius: rem(4);
    border-top-right-radius: rem(4);
    min-width: 100px;
    box-sizing: border-box;
    min-height: rem(93);

    a {
      font-family: Bitter;
      font-size: rem(18);
      font-style: normal;
      font-weight: 600;
      letter-spacing: rem(0.4);
    }
  }

  &__item {
    margin-bottom: rem(16);
  }

  &__desktopDivider {
    display: none;
    margin-top: rem(-16);
  }

  &__item--active {
    background-color: $color-white;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // Align to the bottom of the active tab
      height: rem(16); // Height of the overlap; adjust as needed
      background-color: #fff; // Match the background color of the active tab
      z-index: 6; // Ensure this is higher than the ColorDivider's z-index but lower than the tab content
    }

    a {
      color: $color-brand;
    }
  }

  &__sub {
    display: flex;
    justify-content: center;
    padding: rem(50) 0;
    gap: rem(40);

    hr {
      height: rem(1);
      background-color: $color-ephemeral-blue;
      border: none;
      width: 100%;
    }

    li {
      flex-shrink: 0;
    }
  }

  &__sub-item--active a {
    text-decoration: underline;
    text-underline-offset: rem(5);
  }

  &__sub-item,
  &__sub-item--active {
    a {
      color: $color-brand;
      font-family: $text-font-family;
      font-size: rem(15);
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      letter-spacing: rem(2.4);
      text-transform: uppercase;
    }
  }

  &__sub-item {
    &:hover {
      text-decoration: underline;
      text-underline-offset: rem(5);
    }
  }

  &__drawer {
    svg {
      font-size: rem(20);
    }

    &--toggle {
      display: flex;
      justify-content: space-between;
      background-color: $color-dark-blue-gray;
      padding: rem(15) 0 rem(15) rem(20);
      color: $color-white;
      cursor: pointer;

      &Title {
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 2.1px;
        text-transform: uppercase;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &Icon {
        padding-right: rem(20);
      }
    }

    &--content {
      &TopTab {
        background-color: $color-dark-blue-gray;
        padding: 0 rem(20) rem(20);

        .tabbed-nav__sub-item--active,
        .tabbed-nav__sub-item {
          a {
            color: $color-white;
            font-family: $text-font-family;
            font-size: rem(14);
            font-style: normal;
            font-weight: 300;
            text-transform: capitalize;
            letter-spacing: 0;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: rem(15);
        }

        a {
          color: $color-white;

          &:hover {
            text-decoration: underline;
          }
        }

      }

      &Container {
        background-color: $color-azureish-white;

        .tabbed-nav__sub-item a {
          color: $color-brand;
          font-family: $text-font-family;
          font-size: rem(14);
          font-style: normal;
          font-weight: 400;
          text-transform: capitalize;
          letter-spacing: 0;

          &:hover {
            text-decoration: underline;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: rem(15);
          padding-left: rem(15);
          padding-bottom: rem(15);
        }

        &:not(:last-child) {
          border-bottom: rem(1) solid $color-dark-blue-gray;
        }
      }

      &Tab {
        display: flex;
        justify-content: space-between;

        a {
          font-family: Open Sans;
          font-size: rem(14);
          font-style: normal;
          font-weight: 600;
          line-height: rem(24);
          /* 171.429% */
          letter-spacing: rem(2.1);
          text-transform: uppercase;
          color: $color-brand;
          padding: rem(15) 0 rem(15) rem(15);

          &:hover {
            text-decoration: underline;
          }
        }

        &Toggle {
          padding: 0 rem(15) 0 rem(15);
          cursor: pointer;
          display: flex;
          align-items: center;
          background-color: $color-white;
        }
      }

    }
  }

  @include after-tablet {
    margin-top: rem(-93);

    &__drawer {
      display: none;
    }

    &__desktop {
      display: block;
    }

    &__mobileDivider {
      display: none;
    }

    &__desktopDivider {
      display: grid;
    }
  }
}
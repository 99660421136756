@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.color-groups {
  z-index: 5;
  position: relative;

  &--sm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .color-group {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);

    &--brand-blue {
      span {
        height: 1rem;
        background-color: $color-brand;

        &:nth-of-type(2) {
          background-color: $color-dark-blue-gray;
        }
      }
    }

    &--teal {
      span {
        height: 1rem;
        background-color: $color-teal;

        &:nth-of-type(2) {
          background-color: $color-malibu-blue;
        }
      }
    }

    &--forest-green {
      span {
        height: 1rem;
        background-color: $color-gloucester-green;

        &:nth-of-type(2) {
          background-color: $color-green;
        }
      }
    }

    &--pomegranate-red {
      span {
        height: 1rem;
        background-color: $color-pomegranate-red;

        &:nth-of-type(2) {
          background-color: $color-pumpkin-orange;
        }
      }
    }
  }

  &--lg {

    .color-group {
      display: grid;
      grid-template-columns: 2fr 1fr 6fr 6fr;

      span {
        height: 1rem;
      }

      &--lg-1 {
        background-color: $color-pomegranate-red;
      }

      &--lg-2 {
        background-color: $color-avocado-green;
      }

      &--lg-3 {
        background-color: $color-brand;
      }

      &--lg-4 {
        background-color: $color-malibu-blue;
      }

    }
  }
}
@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';

.map-container {
  .mapboxgl-map {
    width: 100%;
    height: 37.5rem !important;

    @include phone {
      height: 20.5rem !important;
    }

    @include tablet {
      height: 24.5rem !important;
    }

    .popup {
      &__link {
        color: $color-brand;

        &:hover {
          text-decoration: underline;
        }
      }

      &__county-name, &__foundation-name {
        font-weight: bold;
      }
    }

    /* Overrides */
    .mapboxgl-popup-content { word-break: break-word; }
  }
}


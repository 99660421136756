@import 'shared/assets/stylesheets/vars.scss';

// X-Small devices (portrait phones, less than 576px)

@mixin phone {
  @media (max-width: rem(575.98)) {
    @content;
  }
}

@mixin after-phone {
  @media (min-width: rem(575.98)) {
    @content;
  }
}

@mixin after-tablet {
  @media (min-width: rem(991.98)) {
    @content;
  }
}

// Small devices (landscape phones, less than 768px)
@mixin phone-landscape {
  @media (max-width: rem(767.98)) {
    @content;
  }
}

// Medium devices (tablets, less than 992px)
@mixin tablet {
  @media (max-width: rem(991.98)) {
    @content;
  }
}

// Large devices (desktops, less than 1200px)
@mixin desktop {
  @media (max-width: rem(1199.98)) {
    @content;
  }
}

// X-Large devices (large desktops, less than 1400px)
@mixin desktop-large {
  @media (max-width: rem(1399.98)) {
    @content;
  }
}

// all desktop devices
@mixin desktop-all {
  @media (min-width: rem(1199.98)) {
    @content;
  }
}

@mixin after-phone-and-before-desktop {
  @media (min-width: rem(575.98)) and (max-width: rem(1439)) {
    @content;
  }
}

// Adjust line-height for RTE
@mixin adjust-line-height {

  &:has(> span[style*="font-size"]) {
    line-height: unset;
  }

  >span {
    &[style*="font-size: 80px;"] {
      line-height: rem(120);
    }

    &[style*="font-size: 40px;"] {
      line-height: rem(50);
    }

    &[style*="font-size: 28px;"] {
      line-height: rem(38);
    }

    &[style*="font-size: 22px;"] {
      line-height: rem(28);
    }

    &[style*="font-size: 20px;"] {
      line-height: rem(28);
    }

    &[style*="font-size: 18px;"] {
      line-height: rem(28);
    }

    &[style*="font-size: 16px;"] {
      line-height: rem(28);
    }

    &[style*="font-size: 14px;"] {
      line-height: rem(24.8);
    }

    &[style*="font-size: 12px;"] {
      line-height: rem(24.8);
    }

    &[style*="font-size: 10px;"] {
      line-height: rem(24.8);
    }
  }
}


@function rem($value) {
  @return ($value / 16)+rem;
}

@mixin arrow-style($direction) {
  background-repeat: no-repeat;
  background-position: center;
  background-size: rem(14) auto;

  @if $direction ==up {
    background-image: url($arrow-up);
  }

  @else if $direction ==down {
    background-image: url($arrow-down);
  }

  @else if $direction ==left {
    background-image: url($arrow-left);
  }

  @else if $direction ==right {
    background-image: url($arrow-right);
  }
}

@mixin text-color($color1, $color2) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  li::marker,
  p {
    color: $color1;
  }

  a,
  blockquote,
  q,
  cite,
  abbr,
  acronym,
  dt,
  dd,
  b,
  strong,
  i,
  em,
  mark,
  small,
  sub,
  sup,
  ins,
  del,
  pre,
  code,
  kbd,
  samp,
  var,
  address,
  caption {
    color: $color2;
  }

  span {
    strong {
      color: inherit;
      font-family: inherit;
    }
  }
}

//----------- ANIMATIONS -------------

@keyframes btn-blue {
  from {
    background-color: $color-brand;
    transform: scale(1);
    transition: transform .5s ease;
  }

  to {
    background-color: $color-bayern-blue;
    transform: scale(1.2);
  }
}

@keyframes btn-green {
  from {
    background-color: $color-green;
  }

  to {
    background-color: $color-matcha-green;
  }
}

@keyframes btn-white {
  from {
    background-color: $color-white;
    transform: scale(1);
    transition: transform .5s ease;
  }

  to {
    transform: scale(1.2);
    background-color: $color-solitude;
  }
}

@keyframes btn-calendar {
  from {
    background-color: $color-brand;
  }

  to {
    background-color: $color-blue-lolite;
  }
}

@keyframes btn-transparent {
  from {
    background-color: transparent;
  }

  to {
    background-color: $color-brand;
    color: $color-white;
  }
}

@keyframes btn-underline {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes btn-extend-underline {
  from {
    border-bottom: rem(2) solid $color-green;
  }

  to {
    border-bottom: rem(6) solid $color-green;
  }
}

@keyframes btn-extend-underline-small {
  from {
    border-bottom: rem(1) solid $color-green;
  }

  to {
    border-bottom: rem(3) solid $color-green;
  }
}

@keyframes btn-border {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  25% {
    border-top-color: $color-brand;
  }

  50% {
    border-top-color: $color-brand;
    border-right-color: $color-brand;
  }

  75% {
    border-top-color: $color-brand;
    border-right-color: $color-brand;
    border-bottom-color: $color-brand;
  }

  100% {
    border-top-color: $color-brand;
    border-right-color: $color-brand;
    border-bottom-color: $color-brand;
    border-left-color: $color-brand;
  }
}